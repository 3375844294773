import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

import { timer } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { max } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  private endpointPrefix = environment.apiUrl;

  constructor(private http: HttpClient) {}
  public getAllRequest() {
    return this.http.get(this.endpointPrefix + '/reconciliations');
  }
  public getRequestResult(id: number) {
    return this.http.get(
      this.endpointPrefix + '/reconciliations/reports/' + id
    );
  }
  public getRequest(id: number) {
    return this.http.get(this.endpointPrefix + '/reconciliations/' + id);
  }

  public getArchivedRequest(archive: boolean) {
    return this.http.get(
      this.endpointPrefix + '/reconciliations?archive=' + archive
    );
  }

  public createRequest(payload: any): any {
    return this.http.post(this.endpointPrefix + '/reconciliations', payload);
  }
  public pollUntilConditionIsTrue(id: number): any {
    return timer(0, 5000).pipe(
      switchMap(() =>
        this.http.get(this.endpointPrefix + '/reconciliations/reports/' + id)
      ),
      takeWhile(
        (res: any) =>
          res.reconciliation.status === 'DONE' ||
          res.reconciliation.status === 'RECONCILIATION_ERROR'
      )
    );
  }
  public archiveRequest(id: number) {
    return this.http.get(
      this.endpointPrefix + '/reconciliations/' + id + '/archive'
    );
  }

  public calculateSummary(result: any) {
    let totalAmount1 = 0;
    let totalAmount2 = 0;
    let percentage1 = 0;
    let percentage2 = 0;
    let totalDifference = 0;
    let percentageDifference = 0;
    let totalMissing = 0;
    let numberMissingSource1 = 0;
    let totalAmountMissingSource1 = 0;
    let totalAmountEcartSource1 = 0;
    let totalAmountEcartSource2 = 0;
    let totalAmountMissingSource2 = 0;
    let numberMissingSource2 = 0;
    let totalMisMatch = 0;
    let totalCorrect = 0;
    let totalEcartSource1 = 0;
    let totalEcartSource2 = 0;

    result.results.forEach((result: any) => {
      if (result.status === 'MISSING') totalMissing++;
      else if (result.status === 'MISMATCH') totalMisMatch++;
      else if (result.status === 'CORRECT') totalCorrect++;

      if (result.amount1 !== null) totalAmount1 += result.amount1;

      if (result.amount2 !== null) totalAmount2 += result.amount2;
      if (result.amount1 == null && result.amount2 !== null) {
        numberMissingSource2++;
        totalAmountMissingSource2 += result.amount2;
      }

      if (result.amount2 == null && result.amount1 !== null) {
        numberMissingSource1++;
        totalAmountMissingSource1 += result.amount1;
      }

      if (result.amount1 && result.amount2 && result.amount1 < result.amount2) {
        totalAmountEcartSource1 += Math.abs(result.amount1 - result.amount2);
      }
      if (result.amount1 && result.amount2 && result.amount1 > result.amount2) {
        totalAmountEcartSource2 += Math.abs(result.amount1 - result.amount2);
      }
    });
    const total = Math.max(totalAmount1, totalAmount2);
    percentage1 = Math.round((totalAmount1 / total) * 100);
    percentage2 = Math.round((totalAmount2 / total) * 100);
    totalDifference = Math.abs(totalAmount1 - totalAmount2);
    percentageDifference = Math.round((totalDifference / total) * 100);

    return {
      totalAmount1: totalAmount1,
      totalAmount2: totalAmount2,
      percentage1: percentage1,
      percentage2: percentage2,
      totalDifference: totalDifference,
      percentageDifference: percentageDifference,
      totalMissing: totalMissing,
      totalMisMatch: totalMisMatch,
      totalCorrect: totalCorrect,
      numberMissingSource1: numberMissingSource1,
      numberMissingSource2: numberMissingSource2,
      totalAmountMissingSource1: totalAmountMissingSource1,
      totalAmountMissingSource2: totalAmountMissingSource2,
      totalAmountEcartSource1: totalAmountEcartSource1,
      totalAmountEcartSource2: totalAmountEcartSource2,
      totalEcartSource1: totalAmountMissingSource1 + totalAmountEcartSource1,
      totalEcartSource2: totalAmountMissingSource2 + totalAmountEcartSource2,
    };
  }
}
