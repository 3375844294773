import { Pipe, PipeTransform } from '@angular/core';
import {ReconciliationDTO} from "../models/reconciliation";

@Pipe({
  name: 'runtime',
  standalone: true
})
export class RuntimePipe implements PipeTransform {

  transform(reconciliation: ReconciliationDTO, ...args: string[]): string {
    const createdDateTime = new Date(reconciliation?.createdDate).getTime();
    const processEndDateTime = new Date(reconciliation?.processEndDate).getTime();
    const differenceInSeconds = (processEndDateTime - createdDateTime) / 1000;
    return reconciliation?.processEndDate ? 'Traitée en ' + Math.round(differenceInSeconds).toString() + 's' : 'temps de traitement non disponible';
  }

}
