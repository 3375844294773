<header class="flex items-center mb-4">
  <button routerLink="../requestlist" class="button button--secondary mr-4">
    Retour
  </button>
  <h2>Demande de réconciliation</h2>
</header>

<form
  class="w-full p-4 bg-white border-radius border-[1px]"
  [formGroup]="requestForm"
  (ngSubmit)="onSubmit()"
>
  <div class="flex flex-row">
    <div class="input-container mb-4">
      <label for="name" class="label">Renseigner un nom.</label>
      <input id="name" type="text" class="input" formControlName="name" />
      <span
        class="text-error"
        *ngIf="
          requestForm.get('name')?.invalid && requestForm.get('name')?.touched
        "
      >
        Nom obligatoire
      </span>
    </div>
    <div class="input-container" *ngIf="isAdmin">
      <label class="label">Selectionner un client</label>
      <select class="input" (change)="selectCustomer($event)">
        <option value="">Selectionner un client</option>
        <option *ngFor="let customer of customers" value="{{ customer.id }}">
          {{ customer.name }}
        </option>
      </select>
    </div>
  </div>

  <div class="w-full"></div>
  <div formArrayName="sources" class="w-full">
    <div
      class="flex flex-wrap w-full border-l-4 border-primary ml-2 mb-8 relative bg-gray border-radius"
      *ngFor="let source of sources.controls; let i = index"
      [formGroupName]="i"
    >
      <div
        *ngIf="sources.length > 2"
        class="bg-error-light text-error !flex !p-0 border-radius w-[25px] h-[25px] flex items-center justify-center absolute top-1 right-1 cursor-pointer"
        (click)="removeSource(i)"
      >
        <div>x</div>
      </div>
      <h3 class="w-full pl-2">Source {{ i + 1 }}</h3>
      <div class="input-container">
        <label class="label">Selectionner un type.</label>
        <select class="input" formControlName="type">
          <option
            *ngFor="let sourcetype of sourcetypes"
            value="{{ sourcetype.code }}"
          >
            {{ sourcetype.name }}
          </option>
        </select>
        <span
          class="text-error"
          *ngIf="source.get('type')?.invalid && source.get('type')?.touched"
        >
          Le type est requis.
        </span>
      </div>
      <div class="input-container">
        <label class="label"> Fichier source (*) </label>
        <input
          class="input"
          aria-describedby="user_avatar_help"
          type="file"
          formControlName="file"
          (change)="handleFileInput($event, i)"
        />
        <span
          class="text-error"
          *ngIf="source.get('file')?.invalid && source.get('file')?.touched"
        >
          Veuillez uploader un fichier excel ou ods.
        </span>
      </div>
    </div>
    <span
      *ngIf="!loading"
      class="gray ml-2 block cursor-pointer my-6 w-fit"
      (click)="addSource()"
    >
      Ajouter une source
    </span>
  </div>

  <div class="m-2" *ngIf="loading">
    <div role="status">
      <svg
        aria-hidden="true"
        class="w-8 h-8 primary fill-secondary animate-spin"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div
    *ngIf="errorMsg !== ''"
    class="block bg-error-light text-error p-2 my-2 border-radius"
  >
    {{ errorMsg }}
  </div>
  <button *ngIf="!loading" class="button" type="submit">
    Créer une demande
  </button>
</form>
