import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable()
export class errorInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let errMsg =
            "Une erreur inattendue s'est produite. Veuillez réessayer ultérieurement.";
        return next.handle(req).pipe(
            catchError((err) => {
                const error = err.error.detail || err.error.title || errMsg;
                return throwError(error);
            })
        );
    };
}