import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  isAgent() {
    return this.checkRole('ROLE_AGENT')
  }
  isAdmin() {
    return this.checkRole('ROLE_ADMIN')
  }

  checkRole(role: string) {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      const user = JSON.parse(currentUser)
      if (user) {
        const roles = user.authorities;
        return roles.includes(role);
      }
      else return false;
    }
    else return false;

  }
}
