import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import moment from 'moment';

@Directive({
	selector: '[dateTooltip]',
	standalone: true
})
export class DateTooltipDirective {

	@Input() date:Date;
	tootipSet:boolean = false;
	

	@HostListener('mouseenter') onMouseEnter() {
		if (!this.tootipSet) {
			this.el.nativeElement.title = moment(this.date).format('MMMM Do YYYY, h:mm:ss');
			this.tootipSet = true;
		}
	}

	constructor(private el: ElementRef) {}
}
