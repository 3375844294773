import {
  HttpClient,
  HttpClientModule,
  HttpHeaders,
} from '@angular/common/http';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule, CommonModule, HttpClientModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  private endpointPrefix = environment.apiUrl;
  credentials!: FormGroup;
  loading: Boolean = false;
  errorMsg: string = '';
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient
  ) {}
  ngOnInit(): void {
    this.credentials = this.fb.group({
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(4)]],
    });
  }
  onSubmit(event: Event) {
    event.preventDefault();
    if (!this.credentials.valid) {
      this.credentials.markAllAsTouched();
    } else {
      localStorage.clear();
      this.loading = true;
      this.http
        .post(this.endpointPrefix + '/authenticate', this.credentials.value)
        .subscribe(
          (res: any) => {
            this.errorMsg = '';
            this.loading = false;
            const token = res.id_token;
            localStorage.setItem('authenticationToken', token);
            this.http
              .get<any>(`${this.endpointPrefix}/account`)
              .subscribe((res: any) => {
                localStorage.setItem('currentUser', JSON.stringify(res));
                this.router
                  .navigateByUrl('/backoffice/requestlist')
                  .finally(() => location.reload());
              });
          },
          (err: any) => {
            this.errorMsg = err;
            this.loading = false;
          }
        );
    }
  }
}
