import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { CommonModule } from '@angular/common';
import { Result, ResultResponse } from '../../models';
import { FriendlyDatePipe } from '../../pipes/friendly-date.pipe';
import { ReadableStatusPipe } from '../../pipes/readable-status.pipe';
import { FormatAmountPipe } from '../../pipes/format-amount.pipe';
import { RuntimePipe } from '../../pipes/runtime.pipe';
import { RecapComponent } from './recap/recap.component';

import { ReactiveFormsModule } from '@angular/forms';
import { JubooResultComponent } from 'juboo-result';

interface AdditionalInfo {
  label: string;
  show: boolean;
}

@Component({
  selector: 'app-request-detail',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FriendlyDatePipe,
    ReadableStatusPipe,
    FormatAmountPipe,
    RuntimePipe,
    RecapComponent,
    ReactiveFormsModule,
    JubooResultComponent,
  ],
  templateUrl: './request-detail.component.html',
  styleUrl: './request-detail.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class RequestDetailComponent implements OnInit {
  private endpointPrefix = environment.apiUrl;
  loading: boolean = false;
  requestResult: ResultResponse;
  errorMsg: string = '';
  filteredResult: Result[] = [];
  additionalColumns1: AdditionalInfo[] = [];
  additionalColumns2: AdditionalInfo[] = [];
  imgRes: any;
  colorPrimary = 'bg-primary';
  colorSecondary = 'bg-secondary-light';
  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit() {
    this.imgRes = {
      timerUrl: './../../../assets/time-svgrepo-com.svg',
      svgTopUrl: './../../../assets/top-svgrepo-com.svg',
      svgBottomUrl: './../../../assets/bottom-svgrepo-com.svg',
      iconMarkCircle:
        './../../../assets/exclamation-mark-circle-f-svgrepo-com.svg',
      iconCheck: './../../../assets/icons8-check.svg',
    };
    this.route.paramMap.subscribe((params: any) => {
      const requestId = Number(params.get('id'));
      this.loading = true;
      if (requestId) {
        this.http
          .get(this.endpointPrefix + '/reconciliations/reports/' + requestId)
          .subscribe({
            next: (res: any) => {
              this.requestResult = res;
              this.filteredResult = res.results;

              this.getColumnsName(res.results);
              res.reconciliation.errorKey
                ? (this.errorMsg = res.reconciliation.errorMessage)
                : (this.errorMsg = '');
            },
            error: (error: any) => {
              this.errorMsg = error;
            },
            complete: () => {
              this.loading = false;
            },
          });
      } else {
        this.errorMsg = "Aucun ID n'a été renseigné";
      }
    });
  }

  getColumnsName(results: Result[]) {
    for (let elt of results) {
      if (elt.additionalInfos1) {
        Object.keys(elt.additionalInfos1).forEach((item) =>
          this.additionalColumns1.push({ label: item, show: false })
        );
        break;
      }
    }
    for (let elt of results) {
      if (elt.additionalInfos2) {
        Object.keys(elt.additionalInfos2).forEach((item) =>
          this.additionalColumns2.push({ label: item, show: false })
        );
        break;
      }
    }
  }

  download(e: any) {
    console.log(e);
    this.http
      .get(
        this.endpointPrefix +
          `/reconciliations/download/${e.reconciliation.id}?type=${e.source.code}`,
        { responseType: 'blob' }
      )
      .subscribe((data: Blob) => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = url;
        link.download = <string>e.source.path;
        link.click();

        window.URL.revokeObjectURL(url);
      });
  }
}
