import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment/locale/fr';

@Pipe({
	name: 'friendlyDate',
	standalone: true
})
export class FriendlyDatePipe implements PipeTransform {

	transform(date: any, option?: string): string {

		if (option === 'text') {
			const options:any = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
			return (new Date(date)).toLocaleDateString('fr-FR', options);
		}

    return moment(date).locale('fr').calendar(null, {
      sameDay: '[Aujourd\'hui à] HH:mm',
      lastDay: '[Hier à] HH:mm',
      lastWeek: 'dddd [dernier à] HH:mm',
      sameElse: 'dddd, DD MMM 2024 [à] HH:m'
    });
	}
}
