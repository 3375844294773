<div class="w-full p-0 m-0 flex flex-row flex-no-wrap">
  <header class="w-[15%] fixed">
    <nav
      class="relative flex flex-col justify-between w-full min-h-[100vh] bg-white border-r-[1px]"
    >
      <div class="py-2 lg:py-4">
        <a
          class="mb-4 me-5 ms-2 mt-3 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 lg:mb-0 lg:mt-0"
          href="#"
        >
          <img
            src="./../assets/logo-juboo.png"
            class="pl-4 pr-4"
            style="width: 160px"
            alt="TE Logo"
            loading="lazy"
          />
        </a>
        <hr class="my-2" />
        <ul class="w-full list-style-none flex flex-col mt-3">
          <li class="mb-2 px-2">
            <a routerLink="/backoffice/requestlist">Réconciliation</a>
          </li>
          <hr class="bg-black w-[100%] my-3" />
          <li class="mb-2 cursor-pointer" (click)="logoutService.logout()">
            <span class="px-2">Déconnexion</span>
          </li>
        </ul>
      </div>
      <div class="flex flex-row items-end justify-center py-4 w-full">
        <img
          class="h-10 w-10 rounded-full shadow-md object-cover me-2"
          src="{{ imageUrl }}"
          alt="logo"
        />
        <h5 class="text-lg font-medium text-wrap">
          {{
            this.currentUser.customerName
              ? this.currentUser.customerName
              : this.currentUser.firstName + ' ' + this.currentUser.lastName
          }}
        </h5>
      </div>
    </nav>
  </header>

  <main class="w-[85%] ml-[15%] bg-neutral-100">
    <div class="px-8 py-4">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
