import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatAmount',
  standalone: true
})
export class FormatAmountPipe implements PipeTransform {

  transform(amount: any): string {
    let num = Number(amount);
    if (!Number.isFinite(num)) {
      return '0';
    }
    num = Math.abs(num);
    return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');
  }
}
