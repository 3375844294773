import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'friendlyName',
  standalone: true
})
export class FriendlyNamePipe implements PipeTransform {

  transform(name: String): unknown {
    return name.length > 20 ? name.slice(0, 15) + ' ...' : name;
  }

}
