<juboo-result
  [requestResult]="requestResult"
  [additionalColumns1]="additionalColumns1"
  [additionalColumns2]="additionalColumns2"
  [filteredResult]="filteredResult"
  [loading]="loading"
  [back]="true"
  [imgRes]="imgRes"
  [colorPrimary]="colorPrimary"
  [colorSecondary]="colorSecondary"
  (downloadEvent)="download($event)"
>
</juboo-result>
