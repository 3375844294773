import { Injectable } from '@angular/core';

import { RequestService } from './request.service';
import * as XLSX from 'xlsx-js-style';
@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(private requestService: RequestService) {}
  public getEcart(value1: number, value2: number) {
    if (value1 != value2) {
      let max = Math.max(value1, value2);
      let min = Math.min(value1, value2);
      return max - min;
    }
    return null;
  }

  public getDataRecapParse(source1: string, source2: string, data: any): any {
    return [
      ['Nom', 'Valeur'],
      ['Nombre total de transaction conforme', data.totalCorrect],
      ['Nombre total de transaction non conforme', data.totalMisMatch],
      ['Nombre total de transaction sans correspondance', data.totalMissing],
      ['', ''],
      [`Source ${source1}`, data.totalAmount1],
      ['Nombre de transaction sans correspondance', data.numberMissingSource1],
      [
        'Cumul des montants sur les transactions sans correspondance',
        data.totalAmountMissingSource1,
      ],
      [
        'Cumul des montants manquants sur les transactions non conformes',
        data.totalAmountEcartSource1,
      ],
      [
        'Cumul des montants en  surplus sur les transactions non conformes',
        data.totalAmountEcartSource2,
      ],
      ['', ''],
      ['Ecart', data.totalDifference],
      ['', ''],
      [`Source ${source2}`, data.totalAmount2],
      ['Nombre de transaction sans correspondance', data.numberMissingSource2],
      [
        'Cumul des montants sur les transactions sans correspondance',
        data.totalAmountMissingSource2,
      ],
      [
        'Cumul des montants manquants sur les transactions non conformes',
        data.totalAmountEcartSource2,
      ],
      [
        'Cumul des montants en surplus sur les transactions non conformes',
        data.totalAmountEcartSource1,
      ],
    ];
  }

  public getDataDetailParse(
    dataSource: any,
    additionalInfos1: any,
    additionalInfos2: any
  ): any {
    return dataSource.map((data: any) => {
      const row: any = {
        Compte: '',
        Référence: data.reference,
      };
      if (additionalInfos1) {
        additionalInfos1.forEach((elt: any) => {
          row[elt?.label] = data.additionalInfos1
            ? data.additionalInfos1[elt?.label]
            : '';
        });
      }
      row['date1'] = data.date1;
      row['montant1'] = data.amount1;
      row['ecart'] =
        data.amount1 && data.amount2
          ? this.getEcart(data.amount1, data.amount2)
          : '';
      row['montant2'] = data.amount2;
      row['date2'] = data.date2;
      if (additionalInfos2) {
        additionalInfos2.forEach((elt: any) => {
          row[elt.label] = data.additionalInfos2
            ? data.additionalInfos2[elt?.label]
            : '';
        });
      }
      row['Status reconciliation'] =
        data.status == 'CORRECT'
          ? 'Conforme'
          : data.status == 'MISMATCH'
          ? 'Non conforme'
          : data.status == 'MISSING'
          ? 'Pas de correspondance'
          : '';

      return row;
    });
  }

  public calculateColumnWidths(data: any) {
    const keys = Object.keys(data[0]);
    const widths = keys.map((key) => key.length);

    data.forEach((row: any) => {
      keys.forEach((key, colIdx) => {
        const value = row[key]?.toString() || '';
        widths[colIdx] = Math.max(widths[colIdx], value.length);
      });
    });

    return widths.map((w) => ({ wch: w + 4 }));
  }

  public exportDataRecap(source1: any, source2: any, data: any) {
    let dataParse = this.getDataRecapParse(source1.name, source2.name, data);
    let ws = XLSX.utils.json_to_sheet(dataParse, { skipHeader: true });
    ws['!cols'] = [{ wch: 30 }, { wch: 25 }];
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'récapitulatif');
    XLSX.writeFile(
      wb,
      `récapitulatif ${source1.name} et ${source2.name} .xlsx`
    );
  }

  public exportAllData(result: any) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    const newWorkbook = XLSX.utils.book_new();
    let list = this.requestService.calculateSummary(result.requestResult);
    let data = this.getDataRecapParse(
      result.source1.name,
      result.source2.name,
      list
    );
    XLSX.utils.sheet_add_json(
      worksheet,
      this.getDataDetailParse(
        result.filteredResult,
        result.selectedAdditionalInfos1,
        result.selectedAdditionalInfos2
      ),
      { origin: 'A3', skipHeader: true }
    );
    const header = [
      { label: '', colspan: 1 },
      { label: '', colspan: 1 },
      {
        label: result.source1.name,
        colspan: 2 + result.selectedAdditionalInfos1.length,
      },
      { label: '', colspan: 1 },
      {
        label: result.source2.name,
        colspan: 2 + result.selectedAdditionalInfos2.length,
      },
      { label: '', colspan: 1 },
    ];

    const subHeaders = ['Compte', 'Référence'];
    if (result.selectedAdditionalInfos1) {
      result.selectedAdditionalInfos1.forEach((elt: any) => {
        subHeaders.push(elt.label);
      });
    }
    subHeaders.push('Date', 'Montant', 'Ecart', 'Montant', 'Date');
    if (result.selectedAdditionalInfos2) {
      result.selectedAdditionalInfos2.forEach((elt: any) => {
        subHeaders.push(elt.label);
      });
    }
    subHeaders.push('Statut');

    const table2Subheader = [
      'A2',
      'A3',
      'A4',
      'A5',
      'A6',
      'A7',
      'A8',
      'A9',
      'A10',
      'A11',
      'A12',
      'A13',
      'A14',
      'A15',
      'A16',
      'A17',
      'A18',
    ];
    let colIndex = 0;
    header.forEach((headerItem) => {
      worksheet[XLSX.utils.encode_cell({ r: 0, c: colIndex })] = {
        t: 's',
        v: headerItem.label,
      };
      for (let i = 0; i < headerItem.colspan; i++) {
        worksheet[XLSX.utils.encode_cell({ r: 1, c: colIndex })] = {
          t: 's',
          v: subHeaders[colIndex],
        };
        colIndex++;
      }
    });
    const columnWidths = this.calculateColumnWidths(
      this.getDataDetailParse(
        result.filteredResult,
        result.selectedAdditionalInfos1,
        result.selectedAdditionalInfos2
      )
    );

    worksheet['!cols'] = columnWidths;
    worksheet['!merges'] = [
      {
        s: { r: 0, c: 2 },
        e: { r: 0, c: 3 + result.selectedAdditionalInfos1.length },
      },
      {
        s: { r: 0, c: 5 + result.selectedAdditionalInfos1.length },
        e: {
          r: 0,
          c:
            6 +
            result.selectedAdditionalInfos2.length +
            result.selectedAdditionalInfos1.length,
        },
      },
    ];
    subHeaders.forEach((header, index) => {
      worksheet[XLSX.utils.encode_cell({ r: 1, c: index })] = {
        t: 's',
        v: header,
        s: {
          font: {
            bold: true,
            color: { rgb: '000000' },
            sz: 12,
          },
        },
      };
    });

    for (let i = 0; i < subHeaders.length; i++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: i });
      if (!worksheet[cellAddress]) {
        worksheet[cellAddress] = { t: 's', v: '' };
      }
      worksheet[cellAddress].s = {
        font: {
          bold: true,
          color: { rgb: '000000' },
          sz: 14,
        },
      };
    }
    let ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });
    XLSX.utils.book_append_sheet(newWorkbook, ws, 'Récapitulatif');

    XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Détail');

    const columnWidths2 = this.calculateColumnWidths(
      this.getDataRecapParse(result.source1.name, result.source2.name, list)
    );
    ws['!cols'] = columnWidths2;
    ws['A1'].s = {
      font: {
        color: { rgb: '000000' },
        bold: true,
        sz: 14,
      },
    };
    ws['B1'].s = {
      font: {
        color: { rgb: '000000' },
        bold: true,
        sz: 14,
      },
    };

    table2Subheader.forEach((el) => {
      ws[el].s = {
        font: { bold: true, sz: 12 },
      };
    });
    XLSX.writeFile(newWorkbook, `Réconciliation  ${result.name}.xlsx`);
  }
}
