<div class="w-full rounded-md bg-white shadow-md border-[1px]">
  <button
    (click)="toggleDropdown()"
    type="button"
    class="inline-flex rounded-md w-full justify-between px-3 py-2 text-sm font-semibold text-gray-900ring-1 hover:bg-gray-50"
    id="menu-button"
    aria-expanded="true"
    aria-haspopup="true"
  >
    Récapitulatif
    <svg
      class="-mr-1 h-5 w-5 text-gray-400"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fill-rule="evenodd"
        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
        clip-rule="evenodd"
      />
    </svg>
  </button>
  <div id="dataExport" class="w-full flex">
    <div class="w-2/3 mt-4 px-2 py-2 flex flex-col" *ngIf="activeDropDown">
      <details class="flex">
        <summary class="cursor-pointer flex flex-row items-center">
          <div class="w-9/12 flex flex-row justify-between">
            <div [title]="result.reconciliation.source1.name">
              Source {{ result.reconciliation.source1.name | friendlyName }}
            </div>
            <div class="text-right pr-2">
              {{ recap.totalAmount1 | formatAmount }} F
            </div>
          </div>
          <progress
            id="p1"
            [value]="recap.percentage1"
            max="100"
            style="background-color: #a96b4a"
          ></progress>
        </summary>
        <div class="w-9/12 mx-3 flex flex-row justify-between">
          <div class="ml-2 mr-2">Nombre de transaction sans correspondance</div>
          <div class="text-nowrap xl:mr-12 2xl:mr-9 3xl:mr-5">
            {{ recap.numberMissingSource1 }}
          </div>
        </div>
        <div class="w-9/12 mx-3 flex flex-row justify-between">
          <div class="ml-2 mr-2">
            Cumul des montants sur les transactions sans correspondance
          </div>
          <div class="text-nowrap xl:mr-8 2xl:mr-5 3xl:mr-1">
            {{ recap.totalAmountMissingSource1 | formatAmount }} F
          </div>
        </div>

        <div class="w-9/12 mx-3 flex flex-row justify-between">
          <div class="ml-2 mr-2">
            Cumul des montants manquants sur les transactions non conformes
          </div>
          <div class="text-nowrap xl:mr-8 2xl:mr-5 3xl:mr-1">
            {{ recap.totalAmountEcartSource1 | formatAmount }} F
          </div>
        </div>
        <div class="w-9/12 mx-3 flex flex-row justify-between">
          <div class="ml-2 mr-2">
            Cumul des montants en surplus sur les transactions non conformes
          </div>
          <div class="text-nowrap xl:mr-8 2xl:mr-5 3xl:mr-1">
            {{ recap.totalAmountEcartSource2 | formatAmount }} F
          </div>
        </div>
      </details>
      <details>
        <summary class="w-full cursor-pointer flex flex-row items-center">
          <div class="w-9/12 flex flex-row justify-between">
            <div [title]="result.reconciliation.source2.name">
              Source {{ result.reconciliation.source2.name | friendlyName }}
            </div>

            <div class="text-right pr-2">
              {{ recap.totalAmount2 | formatAmount }} F
            </div>
          </div>
          <progress id="p2" [value]="recap.percentage2" max="100"></progress>
        </summary>

        <div class="w-9/12 mx-3 flex flex-row justify-between">
          <div class="ml-2 mr-2">Nombre de transaction sans correspondance</div>
          <div class="text-nowrap xl:mr-12 2xl:mr-9 3xl:mr-5">
            {{ recap.numberMissingSource2 }}
          </div>
        </div>
        <div class="w-9/12 mx-3 flex flex-row justify-between">
          <div lass="ml-2 mr-2">
            Cumul des montants sur les transactions sans correspondance
          </div>
          <div class="text-nowrap xl:mr-8 2xl:mr-5 3xl:mr-1">
            {{ recap.totalAmountMissingSource2 | formatAmount }} F
          </div>
        </div>

        <div class="w-9/12 mx-3 flex flex-row justify-between">
          <div class="ml-2 mr-2">
            Cumul des montants manquants sur les transactions non conformes
          </div>
          <div class="text-nowrap xl:mr-8 2xl:mr-5 3xl:mr-1">
            {{ recap.totalAmountEcartSource2 | formatAmount }} F
          </div>
        </div>
        <div class="w-9/12 mx-3 flex flex-row justify-between">
          <div class="ml-2 mr-2">
            Cumul des montants en surplus sur les transactions non conformes
          </div>
          <div class="text-nowrap xl:mr-8 2xl:mr-5 3xl:mr-1">
            {{ recap.totalAmountEcartSource1 | formatAmount }} F
          </div>
        </div>
      </details>
      <div class="w-full cursor-pointer flex flex-row items-center">
        <div class="w-9/12 ml-4 flex flex-row justify-between">
          <div class="text-[#c73737]">Ecart</div>
          <div class="text-right pr-2">
            {{ recap.totalDifference | formatAmount }} F
          </div>
        </div>
        <progress
          id="p2"
          [value]="recap.percentageDifference"
          max="100"
        ></progress>
      </div>

      <div
        class="text-red-400 p-2 my-2 rounded text-sm"
        *ngIf="
          !recap.percentage1 &&
          !recap.percentage2 &&
          !recap.percentageDifference
        "
      >
        Le Récapitulatif des totaux n'est pas disponible.
      </div>
    </div>
    <div class="w-1/2 mt-4 px-5 py-2 flex flex-col" *ngIf="activeDropDown">
      <div class="w-full flex justify-between items-center py-1">
        <div>Transactions conformes</div>
        <div class="text-right px-2">
          {{ recap.totalCorrect }}
        </div>
      </div>
      <div class="w-full flex justify-between items-center py-1">
        <div>Transactions non conformes</div>
        <div class="text-right px-2">
          {{ recap.totalMisMatch }}
        </div>
      </div>
      <div class="w-full flex justify-between items-center py-1">
        <div>Transactions sans correspondances</div>
        <div class="text-right px-2">
          {{ recap.totalMissing }}
        </div>
      </div>

      <div
        class="text-red-400 p-2 my-2 rounded text-sm"
        *ngIf="
          !recap.percentage1 &&
          !recap.percentage2 &&
          !recap.percentageDifference
        "
      >
        Le Récapitulatif des totaux n'est pas disponible.
      </div>
    </div>
  </div>
</div>
