import { CanActivateFn } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const isAuthenticated = localStorage.getItem('authenticationToken');
  if (!isAuthenticated) {
    router.navigateByUrl('/login');
    return false;
  } else {
    return true;
  }
};
