import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatAmountPipe } from '../../../pipes/format-amount.pipe';
import { ResultResponse } from '../../../models';
import * as XLSX from 'xlsx';
import { FriendlyNamePipe } from '../../../pipes/friendly-name.pipe';
import { RequestService } from '../../request-service/request.service';
import { ExportService } from '../../request-service/export.service';
@Component({
  selector: 'app-recap',
  standalone: true,
  imports: [CommonModule, FormatAmountPipe, FriendlyNamePipe],
  templateUrl: './recap.component.html',
  providers: [RequestService],
  styleUrl: './recap.component.css',
})
export class RecapComponent {
  @Input() result: ResultResponse;
  recap!: any;
  activeDropDown: boolean = true;

  constructor(
    private requestService: RequestService,
    private exportService: ExportService
  ) {}
  public toggleDropdown() {
    this.activeDropDown = !this.activeDropDown;
  }

  ngOnInit() {
    this.recap = this.requestService.calculateSummary(this.result);
  }

  exportDataSummary() {
    this.exportService.exportDataRecap(
      this.result.reconciliation.source1,
      this.result.reconciliation.source2,
      this.recap
    );
  }
}
