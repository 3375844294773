import { Component } from '@angular/core';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ReadableStatusPipe } from '../../pipes/readable-status.pipe';
import { FriendlyDatePipe } from '../../pipes/friendly-date.pipe';
import { DateTooltipDirective } from '../../directives/date-tooltip.directive';
import { RuntimePipe } from '../../pipes/runtime.pipe';
import { Reconciliation } from '../../models/reconciliation';
import { RequestLiComponent } from '../../core/shared/request-li/request-li.component';
import { RequestService } from '../request-service/request.service';
import { RoleService } from '../../core/services/role.service';
@Component({
  selector: 'app-request-list',
  styleUrl: './request-list.component.css',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    ReadableStatusPipe,
    FriendlyDatePipe,
    DateTooltipDirective,
    RuntimePipe,
    RequestLiComponent,
  ],
  templateUrl: './request-list.component.html',
})
export class RequestListComponent {
  loading: boolean = false;
  requestList: any = [];
  archivedRequestList: any = [];
  notArchivedRequestList: any = [];
  errorMsg: string = '';
  infoMsg: string = '';
  displayArchived: boolean = false;
  isAgent: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private requestService: RequestService,
    private roleService: RoleService,
    protected router: Router
  ) { }
  ngOnInit(): void {
    this.loading = true;
    this.isAgent = this.roleService.isAgent();
    if (this.isAgent) {
      this.route.queryParams.subscribe((params) => {
        if (params['archive']) {
          this.displayArchived = true;
        } else {
          this.displayArchived = false;
        }
      });
    }
    this.getRequests();
  }

  toggleArchivelist() {
    this.isAgent ? this.displayArchived = !this.displayArchived : '';
    this.getRequests();
  }
  updateCurrentRequestList(id: number) {
    this.requestList = this.requestList.filter(
      (request: Reconciliation) => request.id !== id
    );
  }
  getRequestLiMessage(message: string) {
    this.infoMsg = message;
  }

  getRequests() {
    this.loading = true;
    if (!this.isAgent) this.displayArchived = false;
    this.requestService.getArchivedRequest(this.displayArchived).subscribe(
      (res: any) => {
        this.requestList = res;
        this.loading = false;
      },
      (err: any) => {
        this.errorMsg = err;
        this.loading = false;
      }
    );
  }

  showDetails(request: any) {
    this.router.navigate([`backoffice/requestdetail/${request.id}`]);
  }

  getProgressStatus(reconciliation: Reconciliation) {
    return this.onLoader.includes(reconciliation.status);
  }

  get onLoader() {
    return ['ON_TRANSFORMATION', 'TO_RECONCILE', 'ON_RECONCILIATION'];
  }
}
