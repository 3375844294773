import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readableStatus',
  standalone: true
})
export class ReadableStatusPipe implements PipeTransform {

	transform(value: string): string {
		switch(value) {
			case 'NEW':
				return 'Nouveau';
			case 'DONE':
				return 'Terminé';
			case 'TRANSFORMATION_ERROR':
				return 'Erreur';
			case 'RECONCILIATION_ERROR':
				return 'Erreur';
			default:
				return 'En cours';
		}
  }
}
