import { Component } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { LogoutService } from '../core/util/logout.service';
import { CommonModule, Location } from '@angular/common';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-backoffice',
  standalone: true,
  imports: [RouterOutlet, RouterModule, CommonModule],
  providers: [LogoutService],
  templateUrl: './backoffice.component.html',
})
export class BackofficeComponent {
  private endpointPrefix = environment.apiUrl;
  currentUser!: any;
  defaultImage = './../assets/defaultImage.webp';
  imageUrl: string;
  constructor(public logoutService: LogoutService, protected router: Router) {}
  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.imageUrl = this.currentUser.customerLogo
      ? `${this.endpointPrefix}/customers/logo?name=${this.currentUser.customerLogo} `
      : this.defaultImage;
  }
}
