import { CanActivateFn } from '@angular/router';
import { RoleService } from '../../services/role.service';
import { inject } from '@angular/core';

export const agentGuard: CanActivateFn = () => {
  const roleService = inject(RoleService);
  return roleService.isAgent();
};
export const adminGuard: CanActivateFn = () => {
  const roleService = inject(RoleService);
  return roleService.isAgent();
};
