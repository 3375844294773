import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { environment } from './../../../environments/environment';
import {
  HttpClient,
  HttpClientModule,
  HttpHeaders,
} from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-request',
  standalone: true,
  templateUrl: './create-request.component.html',
  imports: [ReactiveFormsModule, CommonModule, RouterModule, HttpClientModule],
})
export class CreateRequestComponent {
  private endpointPrefix = environment.apiUrl;
  requestForm!: FormGroup;
  loading: Boolean = false;
  errorMsg: string = '';
  fileData: File[] = [];
  sourcetypes: any[] = [];
  customers: any[] = [];

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.requestForm = this.fb.group({
      name: ['', Validators.required],
      sources: this.fb.array([], Validators.minLength(2)),
    });
    this.addSource();
    this.addSource();

    if (this.isAdmin)
      this.getCustomers();
    else this.getSourceTypes();
  }

  getSourceTypes() {
    this.http.get(this.endpointPrefix + '/source-types')
      .subscribe(
        (res: any) => {
          this.errorMsg = '';
          this.loading = false;
          this.sourcetypes = res;
        },
        (err: any) => {
          this.errorMsg = err;
          this.loading = false;
        }
      )
  }

  getCustomers() {
    this.http.get(this.endpointPrefix + '/customers')
      .subscribe(
        (res: any) => {
          this.errorMsg = '';
          this.loading = false;
          this.customers = res;
        },
        (err: any) => {
          this.errorMsg = err;
          this.loading = false;
        }
      )
  }

  selectCustomer(e: any) {
    this.sourcetypes = this.customers.find((elt: any) => elt.id.toString() === e.target.value).sourceTypes;
  }

  get sources(): FormArray {
    return this.requestForm.get('sources') as FormArray;
  }

  addSource(): void {
    const sourceForm = this.fb.group({
      type: ['', Validators.required],
      file: [null, Validators.required],
    });
    this.sources.push(sourceForm);
  }

  onSubmit(): void {
    if (!this.requestForm.valid) {
      this.requestForm.markAllAsTouched();
    } else {
      this.loading = true;
      const formData = new FormData();
      formData.append('name', this.requestForm.value.name);
      this.requestForm.value.sources.forEach((source: any, index: number) => {
        const file = this.fileData[index];

        if (file) {
          let fileExtensionCorrect: boolean = true;
          if (!file.name) {
            this.errorMsg =
              `Source${index + 1} : ` + 'Le fichier sélectionné est invalide.';
            fileExtensionCorrect = false;
            this.loading = false;
            return;
          }

          const parts = file.name.split('.');
          if (parts.length <= 1) {
            this.errorMsg =
              `Source${index + 1} : ` +
              "Le fichier sélectionné n'a pas d'extension valide.";
            fileExtensionCorrect = false;
            this.loading = false;

            return;
          }

          const extensionPart = parts.pop();
          if (extensionPart === undefined) {
            this.errorMsg =
              `Source${index + 1} : ` +
              "Impossible de déterminer l'extension du fichier.";
            fileExtensionCorrect = false;
            this.loading = false;

            return;
          }

          const extension = extensionPart.toLowerCase();
          const allowedExtensions = ['xls', 'xlsx', 'csv', 'ods', 'txt', 'jrn'];
          if (!allowedExtensions.includes(extension)) {
            this.errorMsg =
              `Source${index + 1} : ` +
              'Veuillez télécharger un fichier Excel (.xls, .xlsx) ou CSV, ods, txt, jrn.';
            fileExtensionCorrect = false;
            this.loading = false;

            return;
          }
          if (fileExtensionCorrect) {
            this.errorMsg = '';
            formData.append(`source${index + 1}`, file);
            formData.append(`typeSource${index + 1}`, source.type);
          }
        }
      });
      if (this.errorMsg === '') {
        this.http
          .post(this.endpointPrefix + '/reconciliations', formData)
          .subscribe(
            (res: any) => {
              this.errorMsg = '';
              this.loading = false;
              this.router.navigateByUrl('/backoffice/requestlist');
            },
            (err: any) => {
              this.errorMsg = err;
              this.loading = false;
            }
          );
      }
    }
  }

  removeSource(index: number) {
    if (this.sources.length > 2) {
      this.sources.removeAt(index);
    } else {
      alert(
        'Vous devez avoir au moins deux sources pour une demande de réconciliation.'
      );
    }
  }

  handleFileInput(event: any, index: number): void {
    const file = event.target.files?.[0];
    if (file) {
      this.fileData[index] = file;
    }
  }

  get isAdmin() {
    const userDetails: any = JSON.parse(localStorage.getItem('currentUser') ?? '' );
    return userDetails.authorities.some((elt: string) => elt === 'ROLE_ADMIN');
  }
}
