import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { LogoutService } from '../util/logout.service';
import {inject, Injectable} from '@angular/core';
import {Observable} from "rxjs";

@Injectable()
export class httpCustomInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    const logoutService = inject(LogoutService);
    const token = localStorage.getItem('authenticationToken') ?? '';
    const lang = 'fr';

    req = req.clone({
      setHeaders: {
        Authorization: token ? `Bearer ${token}` : '',
        'X-Lang': lang
      }
    });

    return handler.handle(req);
  };
}
